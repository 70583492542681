import * as React from 'react';

import { Svg, SvgPath, SvgProps, addWithConfig } from '@rbilabs/universal-components';

const Trophy = ({ fill = '#502314', ...props }: SvgProps) => (
  <Svg width="$4" height="$4" viewBox="0 0 16 16" fill="none" {...props}>
    <SvgPath
      d="M13.8333 2.16667H12.1667V0.5H3.83333V2.16667H2.16667C1.25 2.16667 0.5 2.91667 0.5 3.83333V4.66667C0.5 6.79167 2.1 8.525 4.15833 8.78333C4.68333 10.0333 5.80833 10.975 7.16667 11.25V13.8333H3.83333V15.5H12.1667V13.8333H8.83334V11.25C10.1917 10.975 11.3167 10.0333 11.8417 8.78333C13.9 8.525 15.5 6.79167 15.5 4.66667V3.83333C15.5 2.91667 14.75 2.16667 13.8333 2.16667ZM2.16667 4.66667V3.83333H3.83333V7.01667C2.86667 6.66667 2.16667 5.75 2.16667 4.66667ZM13.8333 4.66667C13.8333 5.75 13.1333 6.66667 12.1667 7.01667V3.83333H13.8333V4.66667Z"
      fill={fill}
    />
  </Svg>
);

export default addWithConfig(Trophy);
